import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { CopyIcon, DownloadIcon } from './icons'

function IconItem(props) {
  const mode = useSelector((state) => state.mode.value)
  const svgRef = useRef(null)
  const iconActionRef = useRef(null)

  function copyIconSVG() {
    let text = svgRef.current.innerHTML
    text = text.replaceAll('<div><div>', '')
    text = text.replaceAll('</div></div>', '')
    if (!navigator.clipboard) {
      // use old commandExec() way
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => triggerClickAnimation())
        .catch(function () {
          console.log('error') // error
        })
    }
  }

  function downloadIcon() {
    let text = svgRef.current.innerHTML
    text = text.replaceAll('<div><div>', '')
    text = text.replaceAll('</div></div>', '')
    const blob = new Blob([text], { type: 'image/svg+xml' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${props.icon}.svg`
    link.click()
    URL.revokeObjectURL(link.href)
    triggerClickAnimation()
  }

  function triggerClickAnimation() {
    iconActionRef.current.classList.add('icon-action-click')
  }

  function removeClickAnimation() {
    iconActionRef.current.classList.remove('icon-action-click')
  }

  return (
    <div
      className="icon-item"
      onClick={() => {
        mode === 'copy' ? copyIconSVG() : downloadIcon()
      }}
      onMouseLeave={removeClickAnimation}
    >
      <div ref={svgRef} className="icon-container">
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('stroke-width', props.width)
            svg.setAttribute('width', props.size)
            svg.setAttribute('height', props.size)
            svg.setAttribute('stroke', props.color)
            svg.removeAttribute('class')
            svg.removeAttribute('data-src')
            svg.removeAttribute('xmlns:xlink')
            svg.removeAttribute('role')
          }}
          src={'/icons/' + props.icon + '.svg'}
        />
      </div>
      <div className="icon-name">{props.icon}</div>
      <span ref={iconActionRef} className="icon-action">
        {mode === 'copy' ? <CopyIcon /> : <DownloadIcon />}
      </span>
    </div>
  )
}

export default IconItem
