import { useRef, useEffect, useCallback } from 'react'
import { SearchIcon, XIcon } from './icons'

function SearchBar(props) {
  const inputRef = useRef()

  const focusInput = useCallback((event) => {
    if (event.key === '/') {
      event.preventDefault()
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', focusInput, false)

    return () => {
      document.removeEventListener('keydown', focusInput, false)
    }
  }, [focusInput])

  return (
    <label className="search-bar">
      <SearchIcon />
      {props.selectedCategory !== 'all' ? (
        <div className="badge">
          {props.selectedCategory}
          <XIcon onClick={() => props.setSelectedCategory('all')} />
        </div>
      ) : (
        <></>
      )}
      <input
        type="search"
        ref={inputRef}
        placeholder="Search icons (Press / to focus)"
        value={props.query}
        onChange={(e) => props.setQuery(e.target.value)}
      />
      {/* <label style={{ position: 'relative', width: '100%' }}>
        <input
          type="search"
          ref={inputRef}
          placeholder="Search icons (Press / to focus)"
          value={props.query}
          onChange={(e) => props.setQuery(e.target.value)}
        />
        {props.query === '' ? (
          <span className="placeholder">
            Search icons{' '}
            <span className="press">
              (Press
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
              >
                <rect width="16" height="16" x="4" y="4" rx="2"></rect>
                <path d="m10 16 4-8"></path>
              </svg>
              to focus)
            </span>
          </span>
        ) : (
          <></>
        )}
      </label> */}
    </label>
  )
}

export default SearchBar
