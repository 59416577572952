import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  width: '1.5',
  size: '24',
  color: 'currentColor',
}

export const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    setWidth: (state, action) => {
      state.width = action.payload
    },
    setSize: (state, action) => {
      state.size = action.payload
    },
    setColor: (state, action) => {
      state.color = action.payload
    },
    reset: () => initialState,
  },
})

export const { setWidth, setSize, setColor, reset } = customizationSlice.actions

export default customizationSlice.reducer
