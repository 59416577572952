import { useSelector, useDispatch } from 'react-redux'
import { toggleTheme } from '../reducers/settingsSlice'
import { MoonIcon, SunIcon } from './icons'

function ThemeToggle() {
  const appTheme = useSelector((state) => state.settings.theme)
  const dispatch = useDispatch()

  return (
    <button className="icon-button" onClick={() => dispatch(toggleTheme())}>
      {appTheme === 'light' ? <MoonIcon /> : <SunIcon />}
    </button>
  )
}

export default ThemeToggle
