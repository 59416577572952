import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { store } from './store'
import { change } from './reducers/modeSlice'
import {
  setWidth,
  setSize,
  setColor,
  reset,
} from './reducers/customizationSlice'
import './App.css'
import SearchBar from './components/SearchBar'
import IconItem from './components/IconItem'
import { categories, colors, icons } from './constants'
import ThemeToggle from './components/ThemeToggle'
import { BackwardIcon, CopyIcon, DownloadIcon } from './components/icons'

function App() {
  const [query, setQuery] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [defaultCustomization, setDefaultCustomization] = useState(false)

  const appTheme = useSelector((state) => state.settings.theme)
  const iconWidth = useSelector((state) => state.customization.width)
  const iconSize = useSelector((state) => state.customization.size)
  const iconColor = useSelector((state) => state.customization.color)
  const mode = useSelector((state) => state.mode.value)

  const dispatch = useDispatch()

  // App Theme Toggle
  useEffect(() => {
    document.body.setAttribute('data-theme', appTheme)
  }, [appTheme])

  useEffect(() => {
    const initialState = {
      width: '1.5',
      size: '24',
      color: 'currentColor',
    }

    setDefaultCustomization(
      JSON.stringify(store.getState().customization) !==
        JSON.stringify(initialState)
    )
  }, [iconWidth, iconSize, iconColor])

  function search(icons) {
    return icons.filter((icon) => {
      let iconName = icon.name.toString().toLowerCase().replace(/-/g, ' ')
      if (icon.categories.includes(selectedCategory)) {
        return (
          iconName.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      } else if (selectedCategory === 'all') {
        return (
          iconName.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      }
      return false
    })
  }

  return (
    <div className="container">
      <div className="sidebar">
        <div className="title">
          <div style={{ fontSize: 16 }}>Lithe Icons</div>
          <div className="align-items">
            <ThemeToggle />
          </div>
          {/* <span className="badge" style={{ textTransform: 'none' }}>
            v1.2
          </span> */}
        </div>
        <div className="section">
          <div className="section-title">Click Action</div>
          <div className="icon-actions">
            <button
              className={['action-button', mode === 'copy' && 'active'].join(
                ' '
              )}
              onClick={() => dispatch(change('copy'))}
            >
              <CopyIcon />
              Copy
            </button>
            <button
              className={[
                'action-button',
                mode === 'download' && 'active',
              ].join(' ')}
              onClick={() => dispatch(change('download'))}
            >
              <DownloadIcon />
              Download
            </button>
          </div>
        </div>
        <div className="section">
          <div className="section-title">
            Customization
            {defaultCustomization && (
              <button
                className="icon-button reset-button"
                onClick={() => dispatch(reset())}
              >
                <BackwardIcon style={{ width: 20, height: 20 }} />
              </button>
            )}
          </div>
          <div>
            <div className="space-between">
              <div>Width</div>
              <div className="secondary-text mono-text">{iconWidth}px</div>
            </div>
            <div className="slider-container">
              <input
                type="range"
                min="1"
                max="2"
                step="0.25"
                className="slider"
                value={iconWidth}
                onChange={(event) => dispatch(setWidth(event.target.value))}
              />
            </div>
          </div>
          <div>
            <div className="space-between">
              <div>Size</div>
              <div className="secondary-text mono-text">{iconSize}px</div>
            </div>
            <div className="slider-container">
              <input
                type="range"
                min="16"
                max="48"
                step="4"
                className="slider"
                value={iconSize}
                onChange={(event) => dispatch(setSize(event.target.value))}
              />
            </div>
          </div>
          <div>
            <div className="space-between">
              <div>Color</div>
              <div className="secondary-text">
                <input
                  className="inline-input mono-text"
                  type="text"
                  value={iconColor}
                  spellCheck="false"
                  autoCapitalize="false"
                  autoCorrect="false"
                  onChange={(event) => dispatch(setColor(event.target.value))}
                />
              </div>
            </div>
            <div className="colors-container">
              {colors.map((color) => {
                return (
                  <div
                    key={color.name}
                    className={'color-btn color-' + color.name}
                    onClick={() => dispatch(setColor(color.hash))}
                  ></div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="section no-bottom-border">
          <div className="section-title">Categories</div>
          <div>
            <ul className="categories disable-scrollbars">
              {categories.map((category) => {
                return (
                  <li
                    key={category.name}
                    className={
                      selectedCategory === category.name ? 'selected' : ''
                    }
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    {/* <SVG
                      src={'/icons/' + category.icon + '.svg'}
                      style={{ flexShrink: 0 }}
                    /> */}
                    <div style={{ flexGrow: 1 }}>{category.name}</div>
                    <div className="icons-count">{category.count}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="footer">
          Made by{' '}
          <a href="https://sayed.ifol.io" target="_blank" rel="noreferrer">
            Sayed
          </a>
        </div>
      </div>
      <div className="main">
        <SearchBar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          query={query}
          setQuery={setQuery}
        />
        <div className="icons-grid">
          {search(icons).map((icon) => {
            return (
              <IconItem
                key={icon.name}
                icon={icon.name}
                width={iconWidth}
                size={iconSize}
                color={iconColor}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default App
