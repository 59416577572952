import { combineReducers, configureStore } from '@reduxjs/toolkit'
import modeReducer from './reducers/modeSlice'
import customizationReducer from './reducers/customizationSlice'
import settingsReducer from './reducers/settingsSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  mode: modeReducer,
  customization: customizationReducer,
  settings: settingsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
