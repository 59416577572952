export const icons = [
  { name: 'accessibility', categories: ['user interface'] },
  { name: 'adjustments', categories: ['photo & video'] },
  { name: 'adjustments-alt', categories: ['photo & video'] },
  { name: 'ai', categories: ['user interface'] },
  { name: 'airplane', categories: ['communication'] },
  { name: 'airplay', categories: ['communication'] },
  { name: 'alignment-center', categories: ['editing'] },
  { name: 'alignment-justify', categories: ['editing'] },
  { name: 'alignment-left', categories: ['editing'] },
  { name: 'alignment-right', categories: ['editing'] },
  { name: 'anchor', categories: [''] },
  { name: 'archive', categories: ['files'] },
  { name: 'arrow-down', categories: ['arrows'] },
  { name: 'arrow-left', categories: ['arrows'] },
  { name: 'arrow-right', categories: ['arrows'] },
  { name: 'arrow-up', categories: ['arrows'] },
  { name: 'asterisk', categories: [''] },
  { name: 'asterisk-square', categories: [''] },
  { name: 'at-symbol', categories: [''] },
  { name: 'attachment', categories: ['editing'] },
  { name: 'backward', categories: ['arrows'] },
  { name: 'badge', categories: ['shapes'] },
  { name: 'badge-check', categories: [''] },
  { name: 'bag', categories: [''] },
  { name: 'bag-alt', categories: [''] },
  { name: 'bag-alt-2', categories: [''] },
  { name: 'ban', categories: [''] },
  { name: 'bar-chart', categories: ['editing'] },
  { name: 'bar-chart-alt', categories: ['editing'] },
  { name: 'battery-0', categories: [''] },
  { name: 'battery-1', categories: [''] },
  { name: 'battery-2', categories: [''] },
  { name: 'battery-3', categories: [''] },
  { name: 'bell', categories: ['communication'] },
  { name: 'bell-alt', categories: ['communication'] },
  { name: 'bluetooth', categories: ['communication'] },
  { name: 'book', categories: [''] },
  { name: 'book-open', categories: [''] },
  { name: 'bookmark', categories: ['user interface', 'editing'] },
  { name: 'bookmark-plus', categories: ['user interface', 'editing'] },
  { name: 'box', categories: ['furniture'] },
  { name: 'briefcase', categories: [''] },
  { name: 'browser', categories: ['user interface'] },
  { name: 'brush', categories: ['photo & video'] },
  { name: 'bug', categories: [''] },
  { name: 'bulb', categories: ['furniture'] },
  { name: 'calculator', categories: [''] },
  { name: 'calendar', categories: ['time'] },
  { name: 'calendar-alt', categories: ['time'] },
  { name: 'camera', categories: ['photo & video'] },
  { name: 'cast', categories: [''] },
  { name: 'chart', categories: ['editing'] },
  { name: 'chat', categories: ['communication'] },
  { name: 'chat-alt', categories: ['communication'] },
  { name: 'check', categories: ['user interface'] },
  { name: 'check-circle', categories: ['user interface'] },
  { name: 'check-hexagon', categories: ['user interface'] },
  { name: 'check-square', categories: ['user interface'] },
  { name: 'chevron-down', categories: ['arrows'] },
  { name: 'chevron-down-circle', categories: ['arrows'] },
  { name: 'chevron-left', categories: ['arrows'] },
  { name: 'chevron-left-circle', categories: ['arrows'] },
  { name: 'chevron-right', categories: ['arrows'] },
  { name: 'chevron-right-circle', categories: ['arrows'] },
  { name: 'chevron-up', categories: ['arrows'] },
  { name: 'chevron-up-circle', categories: ['arrows'] },
  { name: 'circle', categories: ['shapes'] },
  { name: 'clipboard', categories: ['editing'] },
  { name: 'clock', categories: ['time'] },
  { name: 'clock-alt', categories: ['time'] },
  { name: 'cloud', categories: ['weather'] },
  { name: 'cloud-arrow-down', categories: ['arrows', 'user interface'] },
  { name: 'cloud-arrow-up', categories: ['arrows', 'user interface'] },
  { name: 'code', categories: ['editing'] },
  { name: 'coffee', categories: [''] },
  { name: 'colorpicker', categories: ['photo & video'] },
  { name: 'command', categories: ['user interface'] },
  { name: 'compass', categories: [''] },
  { name: 'cookie', categories: [''] },
  { name: 'copy', categories: ['files', 'editing'] },
  { name: 'credit-card', categories: [''] },
  { name: 'cross', categories: ['user interface'] },
  { name: 'cross-circle', categories: ['user interface'] },
  { name: 'cross-square', categories: ['user interface'] },
  { name: 'curly-braces', categories: ['editing'] },
  { name: 'cursor', categories: ['editing'] },
  { name: 'data', categories: ['files'] },
  { name: 'data-alt', categories: ['files'] },
  { name: 'diagram', categories: ['editing'] },
  { name: 'diamond', categories: ['shapes'] },
  { name: 'discord', categories: ['brands'] },
  { name: 'door-arrow-in', categories: ['arrows', 'user interface'] },
  { name: 'door-arrow-out', categories: ['arrows', 'user interface'] },
  { name: 'dots-horizontal', categories: ['user interface'] },
  { name: 'dots-vertical', categories: ['user interface'] },
  { name: 'download', categories: ['user interface'] },
  { name: 'dribbble', categories: ['brands'] },
  { name: 'dropdown', categories: ['user interface', 'arrows'] },
  { name: 'edit', categories: ['editing'] },
  { name: 'egg', categories: ['food'] },
  { name: 'expand', categories: ['arrows'] },
  { name: 'expand-alt', categories: ['arrows'] },
  { name: 'eye', categories: ['photo & video'] },
  { name: 'eye-alt', categories: ['photo & video'] },
  { name: 'face', categories: ['editing'] },
  { name: 'fast-forward', categories: ['photo & video'] },
  { name: 'feed', categories: ['user interface'] },
  { name: 'feed-list', categories: ['user interface'] },
  { name: 'figma', categories: ['brands'] },
  { name: 'film', categories: ['photo & video'] },
  { name: 'filter', categories: ['files'] },
  { name: 'filter-alt', categories: ['files'] },
  { name: 'flag', categories: ['files'] },
  { name: 'folder', categories: ['files'] },
  { name: 'folder-minus', categories: ['files'] },
  { name: 'folder-open', categories: ['files'] },
  { name: 'folder-plus', categories: ['files'] },
  { name: 'forward', categories: ['arrows'] },
  { name: 'fullscreen', categories: ['photo & video'] },
  { name: 'gamepad', categories: ['gaming'] },
  { name: 'gamepad-alt', categories: ['gaming'] },
  { name: 'github', categories: ['brands'] },
  { name: 'globe', categories: ['user interface', 'editing'] },
  { name: 'grid', categories: ['user interface', 'files'] },
  { name: 'grid-minus', categories: ['user interface', 'files'] },
  { name: 'grid-plus', categories: ['user interface', 'files'] },
  { name: 'hashtag', categories: ['user interface'] },
  { name: 'headphones', categories: ['photo & video'] },
  { name: 'heart', categories: ['shapes'] },
  { name: 'help', categories: ['user interface'] },
  { name: 'home', categories: ['user interface'] },
  { name: 'home-alt', categories: ['user interface'] },
  { name: 'hourglass', categories: ['user interface', 'furniture', 'time'] },
  { name: 'image', categories: ['photo & video'] },
  { name: 'inbox', categories: ['communication'] },
  { name: 'info', categories: ['user interface'] },
  { name: 'instagram', categories: ['brands'] },
  { name: 'intersect', categories: ['photo & video'] },
  { name: 'lamp', categories: ['furniture'] },
  { name: 'laptop', categories: [''] },
  { name: 'layers', categories: ['photo & video'] },
  { name: 'layout', categories: ['photo & video'] },
  { name: 'lightning', categories: ['weather'] },
  { name: 'link', categories: ['editing'] },
  { name: 'link-alt', categories: ['editing'] },
  { name: 'list', categories: ['files', 'editing'] },
  { name: 'list-square', categories: ['files', 'editing'] },
  { name: 'loader', categories: ['user interface'] },
  { name: 'location', categories: ['user interface'] },
  { name: 'lock', categories: ['files'] },
  { name: 'lock-open', categories: ['files'] },
  { name: 'mail', categories: ['communication'] },
  { name: 'mail-open', categories: ['communication'] },
  { name: 'map', categories: ['user interface'] },
  { name: 'meat', categories: ['food'] },
  { name: 'medal', categories: [''] },
  { name: 'memory', categories: ['files'] },
  { name: 'microphone', categories: ['photo & video'] },
  { name: 'minus', categories: ['editing'] },
  { name: 'minus-circle', categories: ['editing'] },
  { name: 'minus-square', categories: ['editing'] },
  { name: 'money', categories: [''] },
  { name: 'moon', categories: ['user interface', 'weather'] },
  { name: 'mosque', categories: [''] },
  { name: 'move', categories: ['photo & video'] },
  { name: 'music', categories: ['photo & video'] },
  { name: 'newspaper', categories: ['editing'] },
  { name: 'note', categories: ['files', 'editing'] },
  { name: 'notification', categories: ['user interface'] },
  { name: 'package', categories: ['files'] },
  { name: 'paper', categories: ['files', 'editing'] },
  { name: 'paper-text', categories: ['files', 'editing'] },
  { name: 'pause', categories: ['photo & video'] },
  { name: 'pen', categories: ['editing'] },
  { name: 'pencil', categories: ['editing'] },
  { name: 'people', categories: ['people'] },
  { name: 'person', categories: ['people'] },
  { name: 'person-minus', categories: ['people'] },
  { name: 'person-plus', categories: ['people'] },
  { name: 'phone', categories: ['communication'] },
  { name: 'phone-arrow', categories: ['communication'] },
  { name: 'phone-cross', categories: ['communication'] },
  { name: 'phone-signal', categories: ['communication'] },
  { name: 'pie-chart', categories: ['editing'] },
  { name: 'pin', categories: ['editing'] },
  { name: 'pin-alt', categories: ['editing'] },
  { name: 'plant', categories: [''] },
  { name: 'play', categories: ['photo & video'] },
  { name: 'plus', categories: ['editing'] },
  { name: 'plus-circle', categories: ['editing'] },
  { name: 'plus-square', categories: ['editing'] },
  { name: 'poll', categories: ['editing'] },
  { name: 'power', categories: ['user interface'] },
  { name: 'printer', categories: ['editing'] },
  { name: 'processor', categories: [''] },
  { name: 'pulse', categories: [''] },
  { name: 'puzzle', categories: [''] },
  { name: 'radio', categories: ['user interface'] },
  { name: 'receipt', categories: [''] },
  { name: 'refresh', categories: ['user interface', 'arrows'] },
  { name: 'report-hexagon', categories: [''] },
  { name: 'rewind', categories: ['photo & video'] },
  { name: 'save', categories: ['files'] },
  { name: 'scissors', categories: ['photo & video'] },
  { name: 'screen', categories: ['photo & video'] },
  { name: 'screen-arrow-down', categories: ['photo & video', 'arrows'] },
  { name: 'screen-arrow-up', categories: ['photo & video', 'arrows'] },
  { name: 'search', categories: ['user interface'] },
  { name: 'send', categories: ['communication'] },
  { name: 'send-alt', categories: ['communication'] },
  { name: 'settings', categories: ['user interface'] },
  { name: 'settings-alt', categories: ['user interface'] },
  /* { name: 'settings-alt-2', categories: ['user interface'] }, */
  { name: 'share', categories: ['communication', 'user interface', 'arrows'] },
  { name: 'share-alt', categories: ['communication', 'user interface'] },
  { name: 'shield-check', categories: [''] },
  { name: 'shield', categories: ['shapes'] },
  { name: 'shield-report', categories: [''] },
  { name: 'shopping-cart', categories: [''] },
  { name: 'shrink', categories: ['arrows'] },
  { name: 'shrink-alt', categories: ['arrows'] },
  { name: 'smartphone', categories: [''] },
  { name: 'snowflake', categories: ['weather'] },
  { name: 'sofa', categories: ['furniture'] },
  /* { name: 'sofa-alt', categories: ['furniture'] }, */
  { name: 'sound-wave', categories: ['photo & video'] },
  { name: 'square', categories: ['shapes'] },
  { name: 'star', categories: ['shapes'] },
  { name: 'sticker', categories: ['photo & video'] },
  { name: 'stop', categories: ['photo & video'] },
  { name: 'storage', categories: ['files'] },
  { name: 'sun', categories: ['user interface', 'weather'] },
  { name: 'support', categories: ['user interface'] },
  { name: 'swap', categories: ['arrows'] },
  { name: 'swap-alt', categories: ['arrows'] },
  { name: 'table', categories: ['user interface'] },
  { name: 'tag', categories: [''] },
  { name: 'target', categories: [''] },
  { name: 'tasks', categories: ['files', 'editing', 'user interface'] },
  { name: 'temperature', categories: ['weather'] },
  { name: 'template', categories: ['photo & video'] },
  { name: 'text', categories: ['editing'] },
  { name: 'thumbs-down', categories: ['user interface'] },
  { name: 'thumbs-up', categories: ['user interface'] },
  { name: 'ticket', categories: [''] },
  { name: 'toggle', categories: ['user interface'] },
  { name: 'transform', categories: ['photo & video'] },
  { name: 'trash', categories: ['files'] },
  /* { name: 'trash-alt', categories: ['files'] }, */
  { name: 'tree', categories: [''] },
  { name: 'tv', categories: ['user interface', 'photo & video'] },
  { name: 'twitter', categories: ['brands'] },
  { name: 'twitter-x', categories: ['brands'] },
  { name: 'typeface', categories: ['editing'] },
  { name: 'upload', categories: ['user interface'] },
  { name: 'video', categories: ['photo & video'] },
  { name: 'volume-high', categories: ['photo & video'] },
  { name: 'volume-low', categories: ['photo & video'] },
  { name: 'volume-mute', categories: ['photo & video'] },
  { name: 'wallet', categories: [''] },
  { name: 'wifi', categories: ['user interface', 'communication'] },
  { name: 'window', categories: ['user interface'] },
  { name: 'window-alt', categories: ['user interface'] },
  { name: 'wrench', categories: [''] },
  { name: 'zoom-in', categories: ['user interface'] },
  { name: 'zoom-out', categories: ['user interface'] },
]

function countIcons(category) {
  return category === 'all'
    ? icons.length
    : icons.filter((icon) => icon.categories.includes(category)).length
}

export const categories = [
  { name: 'all', icon: 'grid', count: countIcons('all') },
  { name: 'arrows', icon: 'forward', count: countIcons('arrows') },
  { name: 'brands', icon: 'twitter', count: countIcons('brands') },
  { name: 'communication', icon: 'phone', count: countIcons('communication') },
  { name: 'editing', icon: 'pencil', count: countIcons('editing') },
  { name: 'files', icon: 'folder', count: countIcons('files') },
  /* { name: 'furniture', icon: 'lamp', count: countIcons('furniture') }, */
  /* { name: 'gaming', icon: 'gamepad', count: countIcons('gaming') }, */
  { name: 'photo & video', icon: 'camera', count: countIcons('photo & video') },
  { name: 'people', icon: 'person', count: countIcons('people') },
  { name: 'shapes', icon: 'circle', count: countIcons('shapes') },
  { name: 'time', icon: 'clock', count: countIcons('time') },
  { name: 'user interface', icon: 'home', count: countIcons('user interface') },
  { name: 'weather', icon: 'sun', count: countIcons('weather') },
]

export const colors = [
  { name: 'current', hash: 'currentColor' },
  { name: 'red', hash: '#ef4444' },
  { name: 'green', hash: '#22c55e' },
  { name: 'blue', hash: '#3b82f6' },
  { name: 'purple', hash: '#a855f7' },
]
